import styled from 'styled-components';

const Heading = styled.h2`
	width: 100%;
	margin: 10px 0;
	text-align: left;
	font-size: 2rem;
	font-weight: bold;
	color: ${props => props.color ? `#${props.color}` : '#8f3f97'};
`;

export const Subheading = styled.h3`
	width: 100%;
	margin: 12px 0;
	text-align: left;
	font-size: 1.6rem;
	font-weight: bold;
	color: ${props => props.color ? `#${props.color}` : '#ed008c'};
`;

export default Heading;
