import React, { Fragment } from 'react';
import DownloadButton from '../../components/download-button/download-button';
import Heading, { Subheading } from '../../components/heading/heading';
import Text from '../../components/text/text';
import googleDrivePublic from '../../utils/google-drive-public';

export default function Theater() {
	return(
		<Fragment>
			<Heading>Musiktheaterkurse</Heading>

			<Text>
				für Kinder von 6 bis 12 Jahren
			</Text>

			<Text>
				<p>
					In unseren Kinderkursen <b>Musik &amp; Theater</b> machen wir uns auf die Reise
					in die Welt der großen Opern und Musikwerke.
					Wir besuchen die Trolle in <b>Peer Gynt,</b> die Elfen im <b>Sommernachtstraum,</b>
					das Knusperhäuschen in <b>Hänsel und Gretel,</b>
					erzählen die Zauberflöte mittels Theaterpuppen und Schattentheater
					in sagenhaften 20 Minuten und bringen die verwickelten Abenteuer des tolldreisten
					<b>Barbier von Sevilla</b> auf unsere kleine Bühne.
				</p>
				<p>
					Normalerweise finden unsere Kurse in den <b>Schulferien</b> statt.
					Wir probieren aber immer wieder <b>neue Formate</b> aus
					und nehmen uns regelmäßig <b>neue Stücke</b> vor.
				</p>
			</Text>

			<Subheading>Konzept</Subheading>

			<Text>
				<p>
					Das große Anliegen unserer Kurse ist es,
					Kindern im Alter von 6 bis 12 Jahren <b>Werke</b>
					der <b>Theater- und Musikliteratur</b> nahe zu bringen,
					indem sie diese durchs Selber-Machen, also <b>von innen</b> heraus, kennenlernen.
				</p>
				<p>
					Wir gestalten ein <b>Bühnenbild,</b> stellen <b>Kostüme</b> her,
					<b>singen, musizieren, sprechen, spielen</b>
					und bereiten eine gemeinsame <b>Aufführung</b> vor.
				</p>
				<p>
					So werden neben den <b>fachlichen Kompetenzen</b>
					(Rhythmus- und Sprachgefühl, Musikalität, Gehör, Kreativität)
					auch <b>soziale Fähigkeiten</b> geschult wie
					Selbstbewusstsein, Teamgeist, Konzentration und Disziplin.
				</p>
				<p>
					Für uns ist es immer wieder ein Erlebnis,
					gemeinsam mit den Kindern die Welt des (Musik-)Theaters zu erkunden –
					von der ersten <b>Leseprobe</b> bis zum <b>Premierenapplaus.</b>
				</p>
			</Text>

			<Subheading>Kursleiterinnen</Subheading>

			<Text>
				<p>
					<b>Kristina Gerhard</b> studierte Musik und Musiktheaterregie
					an der Hochschule für Musik und Theater Hamburg
					und assistierte nach dem Studium am Saarländischen Staatstheater.
					Seit 2009 arbeitet sie freiberuflich, inszeniert, schreibt für Fachzeitschriften
					und leitet verschiedene szenische Kurse mit Laien, Semiprofis, Gesangsstudenten
					(u.a. Musikhochschule Köln/Wuppertal, Detmold, Mannheim)
					und mit Kindern.
				</p>
				<p>
					<b>Muriel Moser</b> studierte an der UdK Berlin Musikpädagogik
					mit den Fächern Gesang und Klavier
					und führt derzeit in München eine kleine private Musikschule.
					Seit 2016 ist sie zertifiziertes Mitglied im Tonkünstlerverband Bayern
					für die Bereiche Elementare Musikpädagogik und Gesang.
					Da sie selbst als Schauspielerin an Bühnen in Berlin und Tübingen tätig war,
					ist ihr die musiktheatralische Arbeit mit Kindern und Jugendlichen ein besonderes Anliegen.
					Derzeit ist sie mit eigenen Programmen auch im musikkabarettistischen Bereich tätig.
				</p>
			</Text>

			<Text>
				<p>
					Unser Aktuelles Kursangebot erfahren Sie hier:
				</p>
				
				<p>
					<DownloadButton
						src={googleDrivePublic('1-YeKvQ8UsHZZFOEV039vPM16qdaG0WQG')}
						filename="musik-mit-kindern-musiktheater"
					>
						Aktuelle Musiktheaterkurse
					</DownloadButton>
				</p>
			</Text>
		</Fragment>
	);
}
