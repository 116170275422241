import React from 'react';

export default function FileDownload({ src, filename, children }) {
	const htmlProps = {
		href: src,
		download: filename,
		target: '_blank',
		rel: 'noopener noreferrer',
		style: {
			width: 'inherit',
			height: 'inherit',
		},
	}

	return(
		<a {...htmlProps}>
			{children}
		</a>
	);
}
