import React, { Fragment } from 'react';
import styled from 'styled-components';
import Heading from '../../components/heading/heading';
import Text from '../../components/text/text';
import Table, { Label, Description } from '../../components/table/table';

const AddressLine = styled.p`
	text-align: left;
`;

export default function Imprint() {
	return(
		<Fragment>
			<Heading>Impressum</Heading>
			<Text>Verantwortlich für Text und Inhalt:</Text>
			<AddressLine><b>Muriel Moser</b>, Musikpädagogin</AddressLine>
			<AddressLine>Johann-Sebastian-Bach-Str. 24</AddressLine>
			<AddressLine>80637 München - Neuhausen</AddressLine>
			<Table>
				<Label>Telefon</Label>
				<Description>089 | 12 11 11 41</Description>
				<Label>E-Mail</Label>
				<Description>info@musik-mit-kindern.net</Description>
				<Label>Web</Label>
				<Description>www.musik-mit-kindern.net</Description>
			</Table>
			<Table>
				<Label>Konzeption und Gestaltung</Label>
				<Description>Julia Hoffman</Description>
				<Label>Technische Umsetzung</Label>
				<Description>
					<a href="http://rubengiannotti.com" target="__blank" rel="noopener noreferrer">
						Ruben Giannotti
					</a>
				</Description>
				<Label>Portraitfoto</Label>
				<Description>
					<a href="https://www.helenaheilig.de/" target="__blank" rel="noopener noreferrer">
						Helena Heilig
					</a>
				</Description>
				<Label>Klavierfoto</Label>
				<Description>
					<a href="http://www.minerosfilm.com/" target="__blank" rel="noopener noreferrer">
						Yvonne Aebersoldt
					</a>
				</Description>
			</Table>
			<Text>
				Für alle Hyperlinks gilt:
			</Text>
			<Text>
				Ich betone ausdrücklich, dass ich diese Verweise sorgfälltig geprüft habe,
				allerdings keinerlei Einfluss auf die Gestaltung
				und die Inhalte dieser Webseiten habe.
				Deshalb distanziere ich mich ausdrücklich und höchstvorsorglich
				von den aktuellen und zukünftigen Inhalten dieser Angebote
				und mache mir diese nicht zu eigen.
				Diese Erklärung gilt für alle auf meiner Website angegebenen URL's.
			</Text>
		</Fragment>
	);
}
