import React from 'react';
import styled from 'styled-components';
import useWindowDimensions from '../../hooks/useWindowDimensions/useWindowDimensions';

const ImageWrapper = styled.div`
	margin: 20px 0;
	text-align: left;
`;

export default function Image({ src, alt, scale }) {
	const { width } = useWindowDimensions();
	const style = scale && width > 600 ? {width: scale, height: scale} : {width: '100%', height: '100%'};

	return(
		<ImageWrapper>
			<img src={src} alt={alt} style={style} />
		</ImageWrapper>
	);
}
