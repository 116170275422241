import React, { Fragment } from 'react';
import Heading from '../../components/heading/heading';
import Text from '../../components/text/text';
import Image from '../../components/image/image';
import piano from './piano.jpg';

export default function Piano() {
	return(
		<Fragment>
			<Heading>Klavierunterricht</Heading>
			<Text>
				<p>
					Das Erlernen einer Basis, von grundlegender Pianistik wie Fingerhaltung,
					Entwicklung des Gehörs durch Singen und den Transfer auf das Klavier,
					ist der Schwerpunkt meines Klavierunterrichts.
					Weiterhin werden Harmonien analysiert und Grundlagen des Improvisierens erlernt.
					Aber auch das vierhändige Spiel ist ein wichtiger Bestandteil des Unterrichts,
					da man rhythmische Abläufe spielerisch erkennen kann und einfache Stücke ein größeres Fundament bekommen,
					wenn eine zweite Stimme dazu erklingt.
				</p>
				<p>
					Die Persönlichkeit des Schülers steht beim Einzelunterricht im Vordergrund und bestimmt die Richtung.
					Das bedeutet, dass ich auf den Geschmack des Schülers eingehe, dass ich Ideen in den Unterricht mit einbeziehe.
				</p>
				<p>
					Die frühzeitige Einbeziehung von theoretischen Grundlagen halte ich für absolut wichtig,
					da das Klavierspiel auch ein Handwerk ist,
					mit dem man die unterschiedlichsten Richtungen einschlagen kann.
				</p>
			</Text>
			<Image src={piano} alt="Piano im Spiegel" />
		</Fragment>
	);
}
