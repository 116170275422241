import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import Heading from '../../components/heading/heading';
import Text from '../../components/text/text';
import DisplayLink from '../../components/display-link/display-link';

export default function AdvancedCourses() {
	return(
		<Fragment>
			<Heading>Musikkurse von 4 bis 6 Jahren</Heading>
			<Text>
				<p>
					Das Eigenständige der größer werdenden Kinder zu erkennen
					und in einen schöpferischen Prozess zu lenken,
					sehe ich als den Hauptaspekt meiner Arbeit mit den 4- bis 6-Jährigen.
				</p>
				<p>
					Wie in den Kursen mit den jüngeren Kindern arbeite ich viel mit Bildern vom Reisen.
					Wir fahren mit einem Schiff auf eine Insel, in der es fremde Dinge zu entdecken gibt.
					Die Kinder entwickeln aber auch eigene Ideen, die sie spielerisch darstellen,
					aufs Papier bringen und in Töne verwandeln.
				</p>
				<p>
					Eigene Bilder werden trommelnd als Hörgeschichte dargestellt,
					Instrumente werden erkundet, ausgetestet und somit bekommen die Kinder eine Vorstellung davon,
					was man mit Musik alles machen kann.
				</p>
				<p>
					Notennamen werden zu Tieren, die einem bestimmten Farbsystem zugeordnet werden.
					Entsprechende Farben auf den Flügeln eines selbst gemalten Schmetterlings
					werden beispielsweise so zu einer eigenen Komposition,
					die auf dem Glockenspiel oder dem Klavier nachgespielt wird.
					Hier spielt das Zuhören auch eine zentrale Rolle.
					Oft gibt es diesen magischen Moment:
					„Wie hört sich das jetzt an, was ich da aufgemalt habe?“
				</p>
				<p>
					Auch Werke aus der Oper, der klassischen Musik,
					aber auch moderne Musikstücke und Jazzstücke werden
					gehört, erspielt, begleitet, gemalt und manchmal auch aufgeführt.
				</p>
				<p>
					Die Kurse für die 4- 6-jährigen Kinder sind auf zwei Jahre angelegt und bauen aufeinander auf.
					Natürlich gibt es immer wieder die Möglichkeit, quer einzusteigen, falls ich freie Plätze habe.
				</p>
				<p>
					<DisplayLink>
						<Link to="/schedule">Zu den Kurszeiten und Preisen</Link>
					</DisplayLink>
				</p>
			</Text>
		</Fragment>
	);
}
