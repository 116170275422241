import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	padding: 5px 0;
	text-align: left;
	column-count: 2;

	p {
		padding: 3.5px 0;
	}

	a {
		color: var(--mmk-color-link);

		&:hover {
			color: var(--mmk-color-link-hover);
		}
	}

	b {
		&::before, &::after {
			content: ' ';
		}
	}

	@media(min-width: 1150px) {
		column-count: 1;
	}

	@media(max-width: 600px) {
		column-count: 1;
	}
`;

export default function Text({ children }) {
	return(
		<Wrapper>
			{children}
		</Wrapper>
	);
}
