import React, { Fragment } from 'react';
import Heading, { Subheading } from '../../components/heading/heading';
import Text from '../../components/text/text';
import Image from '../../components/image/image';
import welcome from './welcome.jpg';
import DownloadButton from '../../components/download-button/download-button';
import googleDrivePublic from '../../utils/google-drive-public';

export default function Welcome() {
	return(
		<Fragment>
			<Heading>Willkommen</Heading>
			<Image src={welcome} alt="Portrait Muriel König" scale="60%" />
			<Text>
				<p>
					Seit 2005 biete ich in der Johann Sebastian Bach Straße <b>Musikkurse für Kinder</b> an
					und gebe <b>Klavier- und Gesangsunterricht.</b>
				</p>
				<p>
					Gemeinsam mit der Opernregisseurin und Theaterpädagogin Kristina Gerhard
					habe ich seit 2015 ein weiteres Herzensprojekt im Angebot,
					nämlich die <b>Musiktheaterkurse!</b>&nbsp;
					Große Werke aus der Welt der Oper,
					aber auch der Sinfonien werden in Kleinstformat auf die Bühne gebracht!
				</p>
				<p>
					Musik mit Kindern zu machen heißt für mich, auch zu den eigenen Wurzeln zurückzukehren.
					Was hat mich geprägt, was hat mir Freude gemacht,
					was hat mich nachhaltig inspiriert?
				</p>
				<p>
					Jeder Mensch hat von Anfang an ein Grundbedürfnis, Töne zu machen,
					Rhythmen aufzunehmen und sich zu bewegen,
					so dass mich die <b>elementare Musikpädagogik</b> dahingehend bestärkt hat,
					dass anspruchsvolles Musizieren in jeder Altersstufe möglich ist.
				</p>
				<p>
					Seit 2019 habe ich auch Gruppen für Erwachsene im Angebot,
					in denen ich Stimmbildung anbiete und Songs aus der Pop- und Jazzliteratur,
					Kanons und klassische Stücke erarbeite.
				</p>
			</Text>
			<Subheading>Über mich</Subheading>
			<Text>
				<p>
					Ich habe an der UdK Berlin Musikpädagogik mit den Fächern Gesang und Klavier studiert
					und damals auch schon im Bereich der musikalischen Früherziehung gearbeitet und Klavierunterricht gegeben.
				</p>
				<p>
					Mittlerweile spiele ich noch Akkordeon, Gitarre, singende Säge und Blockflöte.
				</p>
				<p>
					Seit 2015 bin ich eingetragenes Mitglied im Tonkünstlerverband.
				</p>
				<p>
					Außerdem habe ich in Berlin und in Tübingen an kleinen Theatern als Schauspielerin gearbeitet
					und Chanson-Abende gestaltet.
					Nach wie vor trete ich mit eigenen Programmen an Kleinkunstbühnen auf.
				</p>
				<p>
					Das kreative Arbeiten mit Kindern und meine musikalisch-theatralischen Erfahrungen
					empfinde ich als sehr befruchtend und ich bin froh,
					in München einen Raum zur Verfügung zu haben, in dem ich meine Kurse gestalten kann.
				</p>
			</Text>

			<Text>
				<DownloadButton
					src={googleDrivePublic('1MIEmojq-qZN-0uIPMAIdIh9MrfeuwCNo')}
					filename={`musik-mit-kindern-aktuelles-${Date.now()}`}
				>
					Aktuelles
				</DownloadButton>
			</Text>
		</Fragment>
	);
}
