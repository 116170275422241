import React, { Fragment } from 'react';
import styled from 'styled-components';
import Heading from '../../components/heading/heading';
import Table, { Label, Description } from '../../components/table/table';
import Image from '../../components/image/image';
import approach from './approach.gif';

const AddressLine = styled.p`
	text-align: left;
`;

const MapWrapper = styled.div`
	width: 100%;
	height: 100%;
	min-height: 400px;

	iframe, img {
		width: inherit;
	}

	@media(min-width: 1150px) {
		img {
			width: 75%;
		}
	}
`;

export default function Contact() {
	return(
		<Fragment>
			<Heading>Kontakt</Heading>
			<AddressLine><b>Muriel Moser</b>, Musikpädagogin</AddressLine>
			<AddressLine>Johann-Sebastian-Bach-Str. 24</AddressLine>
			<AddressLine>80637 München - Neuhausen</AddressLine>
			<Table>
				<Label>Telefon</Label>
				<Description>089 | 12 11 11 41</Description>
				<Label>E-Mail</Label>
				<Description>info@musik-mit-kindern.net</Description>
				<Label>Web</Label>
				<Description>www.musik-mit-kindern.net</Description>
			</Table>
			<Heading>Anfahrt</Heading>
			<MapWrapper>
				{/* // google maps embed
				<iframe
					title="contact-map"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2661.4655523824485!2d11.533716715458894!3d48.159107979225226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479e766b343b773d%3A0xc971594adb348382!2sJohann-Sebastian-Bach-Stra%C3%9Fe%2024%2C%2080637%20M%C3%BCnchen!5e0!3m2!1sde!2sde!4v1602522857882!5m2!1sde!2sde"
					height="400"
					frameBorder="0"
					style={{border: '0'}}
					allowfullscreen=""
					aria-hidden="false"
					tabindex="0"
				></iframe>
				*/}
				<Image src={approach} alt="Anfahrt" scale="42%" />
			</MapWrapper>
		</Fragment>
	);
}
