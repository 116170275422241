import styled from 'styled-components';

const Table = styled.div`
	display: grid;
	grid-template-columns: minmax(50px, auto) 1fr;
	margin: 10px 0;
	text-align: left;
`;

export const Label = styled.div`
	grid-column: 1 / span 1;
	padding: 0 20px 0 0;
	font-weight: bold;
`;

export const Description = styled.div`
	grid-column: 2 / span 1;
`;

export default Table;
