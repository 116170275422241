import React from 'react';
import DisplayLink from '../display-link/display-link';
import FileDownload from '../file-download/file-download';
import './download-button.css';

export default function DownloadButton({ src, filename, children }) {
	return(
		<DisplayLink>
			<FileDownload
				src={src}
				filename={filename}
			>
				<span className="download-button">
					{children}
				</span>
			</FileDownload>
		</DisplayLink>
	);
}