import React, { useState, useEffect } from 'react';
import { Router, createHistory, LocationProvider } from '@reach/router';
import styled, { keyframes } from 'styled-components';
import { Transition } from 'react-transition-group';

import Navigation, { NavigationToggle } from './components/navigation/navigation';
import Burger from './components/burger/burger';
import Scroll from './components/scroll/scroll';

import Welcome from './routes/welcome/welcome';
import ParentChild from './routes/parent-child/parent-child';
import AdvancedCourses from './routes/advanced-courses/advanced-courses';
import Theater from './routes/theater/theater';
import Piano from './routes/piano/piano';
import SingingAdults from './routes/singing-adults/singing-adults';
import Schedule from './routes/schedule/schedule';
import Contact from './routes/contact/contact';
import Imprint from './routes/imprint/imprint';

import brand from './graphics/brand.svg';
import schiff from './graphics/ship.svg';
import fishes from './graphics/fishes.svg';
import { ReactComponent as Ship } from './graphics/intro.svg';
import './App.css';

const Grid = styled.div`
	display: grid;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 10% 1fr 32px;
	width: 100%;
	height: 100%;

	@media(min-width: 1150px) {
		grid-template-rows: 80px 1fr 32px;
		width: 70%;
		height: 75%;
	}
`;

const Content = styled.div`
	position: relative;
	grid-column: 1 / span 2;
  grid-row: 2 / span 1;
	background: #e0f3fc;
	font-size: .95rem;

	@media(min-width: 1150px) {
		grid-column: 2 / span 1;
	}
`;

const Brand = styled.div`
	grid-column: 1 / span 2;
	grid-row: 1 / span 1;
	position: relative;

	@media(min-width: 1150px) {
		grid-column: 2 / span 1;
	}
`;

const Footer = styled.div`
	grid-column: 1 / span 2;
	grid-row: 3 / span 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: 4px 0 0 0;
	background: #e0f3fc;

	@media(min-width: 1150px) {
		grid-column: 2 / span 1;
	}

	span {
		margin: 0 4px;
		padding: 2px 0;
		cursor: pointer;
		color: #221d23;

		&:hover {
			text-decoration: underline;
		}
	}

	a {
		text-decoration: none;

		a:link, &:hover, &:visited, &:focus {
			color: inherit;
		}
	}
`;

const navDduration = 300;

const navDefault = {
	transition: `transform ${navDduration}ms ease-in-out`,
	transform: 'translateX(0)',
}

const navTransition = {
	entering: { transform: 'translateX(0)' },
	entered:  { transform: 'translateX(0)' },
	exiting:  { transform: 'translateX(-100%)' },
	exited:   { transform: 'translateX(-100%)' },
};

const NavigationContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	position: fixed;
	top: 0;
	left: 0;
	grid-column: 1 / span 1;
  grid-row: 2 / span 1;
	width: 100vw;
	height: 100vh;
	padding: 0;
	background: #c5dce6;
	transform: translateX(0);
	z-index: 999;

	@media(min-width: 1150px) {
		position: static;
		display: block;
		width: 100%;
		height: 100%;
		padding: 0 3px 0 0;
		background: transparent;
		transform: translateX(0) !important;
	}
`;

const introDduration = 600;

const introDefault = {
	transition: `opacity ${introDduration}ms ease-in-out 2400ms`,
	opacity: '0',
}

const introTransition = {
	entering: { opacity: '0' },
	entered:  { opacity: '0' },
	exiting:  { opacity: '1' },
	exited:   { opacity: '1' },
};

const intro = keyframes`
	0% { transform: rotate(0deg) translate(0, 0); }
	10% { transform: rotate(.5deg) translate(1px, 0); }
	30% { transform: rotate(0deg) translate(-.5px, .5px); }
	40% { transform: rotate(-.75deg) translate(-1px, -.5px); }
	60% { transform: rotate(0deg) translate(.5px, 0); }
	70% { transform: rotate(1deg) translate(-.5px, 1px); }
	100% { transform: rotate(-.25deg) translate(0, 0); }
`;

const Intro = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #c7eafb;
	pointer-events: none;
	z-index: 9999;

	svg {
		width: 100%;
		height: 100%;
	}

	#ship {
		animation: ${intro} 4s linear infinite alternate;
	}
`;

let history = createHistory(window);

function App() {
	const [mounted, setMounted] = useState(false);
	const [nav, setNav] = useState(false);

	useEffect(() => setMounted(true), []);

	return (
		<div className="App">
			<Transition in={mounted} timeout={introDduration}>
				{state => (
					<Intro style={{
						...introDefault,
						...introTransition[state]
					}}>
						<Ship aria-hidden="true" />
					</Intro>
				)}
			</Transition>
			<Grid>
				<NavigationToggle onClick={() => setNav(!nav)}>
					<Burger state={nav} />
				</NavigationToggle>
				<Transition in={nav} timeout={navDduration}>
					{state => (
						<NavigationContainer style={{
							...navDefault,
							...navTransition[state]
						}}>
							<Navigation toggleHandler={setNav} navigationHandler={history.navigate} />
						</NavigationContainer>
					)}
				</Transition>
				<Brand>
					<header className="App-header">
						<img src={brand} className="App-logo" alt="Brand logo (Musik mit Kindern)" />
					</header>
				</Brand>
				<Content>
					<img src={schiff} className="App-deco-ship" alt="Schiff" />
					<Scroll>
						<LocationProvider history={history}>
							<Router>
								<Welcome path="/" />
								<ParentChild path="parent-child" />
								<AdvancedCourses path="advanced-courses" />
								<Theater path="theater" />
								<Piano path="piano" />
								<SingingAdults path="singing-adults" />
								<Schedule path="schedule" />
								<Contact path="contact" />
								<Imprint path="imprint" />
							</Router>
						</LocationProvider>
						<img src={fishes} className="App-deco-fishes" alt="fishes" />
					</Scroll>
				</Content>
				<Footer>
					<span onClick={() => history.navigate('/imprint')}>Impressum</span>
					|
					<span onClick={() => window.print()}>Seite drucken</span>
				</Footer>
			</Grid>
    </div>
  );
}

export default App;
