import React from 'react';
import styled from 'styled-components';
//import { Link } from "@reach/router";

export const NavigationToggle = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 15px;
	left: 15px;
	width: 50px;
	height: 50px;
	background: transparent;
	border: none;
	outline: none;
	z-index: 1001;

	@media(min-width: 1150px) {
		display: none;
		opacity: 0;
		pointer-events: none;
	}
`;

const colorSelector = idx => {
	if(idx % 8 === 0) {
		return '8f3f97';
	} else if(idx % 8 === 1) {
		return '70995c';
	} else if(idx % 8 === 2) {
		return 'ed008c';
	} else if(idx % 8 === 3) {
		return '00aeef';
	} else if(idx % 8 === 4) {
		return '942723';
	} else if(idx % 8 === 5) {
		return 'd73f48';
	} else if(idx % 8 === 6) {
		return 'ff9933';
	} else if(idx % 8 === 7) {
		return '4e8a92';
	}
}

const NavigationButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 100px;
	height: 25px;
	margin: 0 0 10px 0;
	padding: 0 35px;
	background: #e0f3fc;
	font-size: .8rem;
	color: #221d23;
	color: #${props => colorSelector(props.idx)};
	font-weight: bold;
	cursor: pointer;

	@media(min-width: 1150px) {
		padding: 0 10px;
	}

	&:hover {
		filter: grayscale(.75);
		transition: filter .2s ease;
	}

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		color: inherit;
		text-decoration: none;
	}
`;

export default function Navigation({ toggleHandler, navigationHandler }) {
	return(
		<div onClick={() => toggleHandler(false)}>
			<NavigationButton idx={0} onClick={() => navigationHandler('/')}>
				Willkommen / Aktuelles
			</NavigationButton>
			<NavigationButton idx={1} onClick={() => navigationHandler('/parent-child')}>
				Eltern-Kind-Gruppen
			</NavigationButton>
			<NavigationButton idx={2} onClick={() => navigationHandler('/advanced-courses')}>
				Musikkurse von 4 bis 6 Jahren
			</NavigationButton>
			<NavigationButton idx={3} onClick={() => navigationHandler('/theater')}>
				Musiktheaterkurse
				</NavigationButton>
			<NavigationButton idx={4} onClick={() => navigationHandler('/piano')}>
				Klavierunterricht
			</NavigationButton>
			<NavigationButton idx={5} onClick={() => navigationHandler('/singing-adults')}>
				Singen mit Erwachsenen
			</NavigationButton>
			<NavigationButton idx={6} onClick={() => navigationHandler('/schedule')}>
				Kurszeiten / Preise
			</NavigationButton>
			<NavigationButton idx={7} onClick={() => navigationHandler('/contact')}>
				Anfahrt / Kontakt
			</NavigationButton>
		</div>
	);
}
