import React, { Fragment } from 'react';
import DownloadButton from '../../components/download-button/download-button';
import Heading from '../../components/heading/heading';
import Text from '../../components/text/text';
import googleDrivePublic from '../../utils/google-drive-public';

export default function SingingAdults() {
	return(
		<Fragment>
			<Heading>Singen mit Erwachsenen</Heading>

			<Text>
				<p>
					Neben dem individuellen Einzelunterricht für Gesang
					biete ich seit auch ein offenes Singen in meinem Musikraum an.
				</p>
				<p>
					Nach einem körperlichen und stimmtechnischen Warm Up
					legen wir mit bekannten und unbekannten Stücken ein- und mehrstimmig los.
					Wünsche sind willkommen, die Genres werden gerne wild durcheinandergemischt!
				</p>
				<p>
					Da Singen Balsam für die Seele ist
					und die Stimme im Alltag oft nur eingeschränkt genutzt wird,
					liegt mir die Stimmbildung besonders am Herzen.
				</p>
			</Text>

			<Text>
				<p>
					Näheres erfahrt ihr hier:
				</p>
				<p>
					<DownloadButton
						src={googleDrivePublic('1_fLvrU9oy6rGxgDoCoAZIihz4-eDwIsV')}
						filename="singen-mit-erwachsenen-aktuelles"
					>
						Aktuelles
					</DownloadButton>
				</p>
			</Text>
		</Fragment>
	);
}
