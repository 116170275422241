import React, { Fragment } from 'react';
import { Link } from '@reach/router';
import Heading from '../../components/heading/heading';
import Text from '../../components/text/text';
import DisplayLink from '../../components/display-link/display-link';

export default function ParentChild() {
	return(
		<Fragment>
			<Heading>Eltern-Kind-Gruppen</Heading>
			<Text>
				<p>
					Diese Kurse wenden sich im wörtlichen Sinne an Eltern (oder andere Bezugspersonen) und an Kinder.
					Lieder, Sprechverse, Tänze und Kniereiter bieten einen großen Schatz
					an musikalischen Ausdrucksmöglichkeiten,
					die eine Bereicherung sein können für das tägliche Leben.
				</p>
				<p>
					Im Rahmen eines ritualisierten Anfangsteils und einem ebenfalls stets gleich ablaufenden Schlussteils
					gehen wir auf musikalische Entdeckungsreise.
					Mit dem Bild des Musikschiffes reisen wir zu verschiedenen Inseln und erleben
					Tiere, Piraten, Seeschlangen, neue Orte und fremde Klänge.
					Das Spielen von Tieren spielt immer wieder eine große Rolle:
					wir können wie die Affen springen, wie Elefanten trompeten und wie Katzen schleichen.
					Dazu gibt es auch wunderbare Musikstücke
					aus dem Bereich der Klassik, des Klezmer, des Chanson und des Jazz.
					Wenn eine Musik vom Band erklingt,
					die plötzlich eine Assoziation an ein eben noch gespieltes Tierhervorruft,
					sind auch die Erwachsenen oft ganz erstaunt und bereichert.
				</p>
				<p>
					Ich gebe die Impulse, stelle neue Lieder vor oder teile Musikinstrumente aus,
					lasse aber das Entdecken und das freie Spielen zu.
				</p>
				<p>
					Ich leite diese Kurse nun schon seit vielen Jahren
					und bin immer wieder begeistert von den Kleinsten.
					Ihre unverstellte Neugier auf die Welt ist wunderbar und ich finde es fantastisch,
					einen kleinen Beitrag in ihrer musikalischen Weiterentwicklung leisten zu dürfen.
				</p>
				<p>
					<DisplayLink>
						<Link to="/schedule">Zu den Kurszeiten und Preisen</Link>
					</DisplayLink>
				</p>
			</Text>
		</Fragment>
	);
}
