import React, { Fragment } from 'react';
import googleDrivePublic from '../../utils/google-drive-public';
import DownloadButton from '../../components/download-button/download-button';
//import { Link } from "@reach/router";
import Heading from '../../components/heading/heading';// { Subheading }
import Text from '../../components/text/text';
//import Table, { Label, Description } from '../../components/table/table';

export default function Schedule() {
	return(
		<Fragment>
			<Heading>Kurszeiten / Preise</Heading>

			<Text>
				Die aktuellen Kurszeiten und Preise können Sie
				dieser PDF-Übersicht entnehmen:
			</Text>

			<Text>
				<DownloadButton
					src={googleDrivePublic('1P0LjoEZnAjytmFICRxo_1nq4h4wDwM5n')}
					filename="musik-mit-kindern-kurszeiten-preise"
				>
					Kurszeiten und Preise
				</DownloadButton>
			</Text>
			{/* <Heading>Kurszeiten</Heading>
			<Subheading>Preise</Subheading>
			<Text>
				<p>
					Die Kurse und auch der Klavierunterricht beginnen im September,
					direkt nach den Sommerferien und enden im Juli, direkt vor den Sommerferien.
				</p>
				<p>
					In den Ferien findet kein Unterricht statt,
					von mir versäumte Stunden werden mit einem online-Angebot
					(Mitmachvideos, Hörspiele und Zoom-Meetings)
					nachgeholt.
				</p>
				<p>
					Die Musikkurse kosten pro Halbjahr 180,- Euro.
				</p>
				<p>
					Die Preise für den Klavierunterricht betragen monatlich 80,- Euro
					für eine halbe Stunde und 110,- Euro für eine Dreiviertelstunde.
					Die Ferien werden durchbezahlt.
				</p>
				<p>
					Der Gesangsunterricht wird individuell bezahlt.
					Die Teilnahme am gemeinsamen Singen beträgt 15,- Euro pro Stunde.
					Die Termine müssen jeweils abgeklärt werden.
				</p>
			</Text>
			<Subheading>Kurszeiten</Subheading>
			<Text>
				Aufgrund der aktuellen Situation biete ich die Eltern-Kind-Gruppen momentan nicht an.
			</Text>
			<Table>
				<Label>Montag</Label>
				<Description>16.00 Uhr: Musik mit 5-6-Jährigen</Description>
				<Label>Mittwoch</Label>
				<Description>16.00 Uhr: Musik mit 5-6-Jährigen</Description>
			</Table> */}
			{/* // old schedule (pre corona)
			<Table>
				<Label>Montag</Label>
				<Description>15.30 Uhr: Eltern-Kind-Gruppe (ca. 1 bis 3 Jahre)</Description>
				<Label>Dienstag</Label>
				<Description>
					<p>15.30 Uhr: Eltern-Kind-Gruppe (ca. 1 bis 3 Jahre)</p>
					<p>16.30 Uhr: Musikalische Früherziehung (ab 4 Jahre)</p>
				</Description>
				<Label>Mittwoch</Label>
				<Description>
					<p>09.30 Uhr: Eltern-Kind-Gruppe (ca. 1 bis 3 Jahre)</p>
					<p>16.00 Uhr: Musikalische Früherziehung (ab 4 Jahre)</p>
					<p>17.00 Uhr: Musiktheaterkurs (siehe <Link to="/theater">Musik & Theater</Link>)</p>
				</Description>
			</Table>
			<p>Klavierunterricht nach Vereinbarung.</p>
			*/}
		</Fragment>
	);
}
