import styled from 'styled-components';

const Scroll = styled.div`
	padding: 50px 35px 25px 85px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1; 
	}

	&::-webkit-scrollbar-thumb {
		background: #777;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

	@media(min-width: 1150px) {
		max-height: 65vh;
		padding-left: 35px;
	}
`;

export default Scroll;