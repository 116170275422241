import React from 'react';
import './display-link.css';

export default function DisplayLink({ children }) {
	return(
		<div className="display-link">
			<div>
				{children}
			</div>
		</div>
	);
}
