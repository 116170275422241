import React from 'react';
import './burger.css';

export default function Burger({ state }) {
	return(
		<div className={`nav-burger ${state ? 'transformed left' : ''}`}>
			<span></span>
		</div>
	);
}
